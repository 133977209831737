import type { InitAppForPageFn } from '@wix/yoshi-flow-editor';
import { configure } from 'mobx';

import { Experiment } from './constants';
import {
  createPublicAPI as currentMACreatePublicAPI,
  fetchMenusData,
  initApplication,
  oldInitApplication,
} from './current-ma';
import { createPublicAPI as maV2CreatePublicAPI } from './ma-v2';
import { createPublicAPI as maV3CreatePublicAPI } from './ma-v3';
import { initAppDataStore } from './services/app-data-store';
import { globalAppState } from './services/global-app-state';
import { WarmupDataService } from './services/warmup-data';
import type { RouteConfiguration, ViewerPublicAPI } from './types';
import { initializeMonitoring, toMonitored } from './utils/monitoring';
import {
  isProfilePageBoBInstalled,
  isSettingsPageInstalled,
} from './utils/site';

enum MembersAreaContext {
  V1 = 'v1',
  V2 = 'v2',
  V3 = 'v3',
}

type PublicAPIFactory = () => ViewerPublicAPI;

configure({ isolateGlobalState: true });

const getMembersAreaContext = async () => {
  const wixCodeApi = globalAppState.getWixCodeAPI();

  const [shouldUseV3Context, shouldUseV2Context] = await Promise.all([
    isSettingsPageInstalled(wixCodeApi),
    isProfilePageBoBInstalled(wixCodeApi),
  ]);

  if (shouldUseV3Context) {
    return MembersAreaContext.V3;
  } else if (shouldUseV2Context) {
    return MembersAreaContext.V2;
  }

  return MembersAreaContext.V1;
};

const publicAPIMap: Record<MembersAreaContext, PublicAPIFactory> = {
  [MembersAreaContext.V1]: currentMACreatePublicAPI,
  [MembersAreaContext.V2]: maV2CreatePublicAPI,
  [MembersAreaContext.V3]: maV3CreatePublicAPI,
};

const getViewerPlatformExports = async () => {
  const membersAreaContext = await getMembersAreaContext();
  const publicAPIFactory = publicAPIMap[membersAreaContext];

  return publicAPIFactory();
};

export const initAppForPage: InitAppForPageFn = async (
  initParams,
  platformApis,
  wixCodeApi,
  platformServices,
  flowApi,
) => {
  // @ts-expect-error - missing publicData type
  const publicAppData = initParams.publicData?.APP;
  const routes: RouteConfiguration[] = publicAppData?.routes ?? [];
  const settingsRoutes: RouteConfiguration[] =
    publicAppData?.settingsRoutes ?? [];

  initializeMonitoring(initParams, platformServices);
  globalAppState.setFlowAPI(flowApi);
  globalAppState.setWixCodeAPI(wixCodeApi);
  globalAppState.setRoutes(routes);
  globalAppState.setSettingsRoutes(settingsRoutes);

  const isRemovePromisesInitAppForPageEnabled = flowApi.experiments.enabled(
    Experiment.RemovePromisesInitAppForPage,
  );

  if (isRemovePromisesInitAppForPageEnabled) {
    const warmupDataService = new WarmupDataService(
      wixCodeApi.window.warmupData,
    );

    const { userService } = initApplication(
      initParams as any,
      platformApis as any,
      wixCodeApi,
      flowApi,
      platformServices,
    );

    let initPromise: Promise<any> | null = null;

    return {
      initApplication: toMonitored('initAppForPage', () => {
        if (initPromise === null) {
          initPromise = (async () => {
            await userService.setCurrentUser(
              wixCodeApi.user.currentUser,
              flowApi.httpClient,
            );

            return fetchMenusData({
              appParams: initParams,
              wixCodeApi,
              flowAPI: flowApi,
              warmupDataService,
            });
          })();
        }

        return initPromise;
      }),
    };
  } else {
    const appDataStore = initAppDataStore(initParams, wixCodeApi, flowApi);
    const initApplicationPromise = toMonitored('initAppForPage', () =>
      oldInitApplication(
        initParams as any,
        platformApis as any,
        wixCodeApi,
        flowApi,
        platformServices,
      ),
    )();

    return {
      initApplication: async () => {
        await initApplicationPromise;
        return appDataStore.getAppData();
      },
    };
  }
};

export const exports = () => {
  return getViewerPlatformExports();
};
