import {
  membersApiMediaIndexURL,
  membersApiPictureURL,
} from '../../../../../constants/urls';
import type { FlowApi, MediaPlatformImage } from '../../../../../types';

type MediaCredentialsResponse = {
  uploadToken: string;
  uploadUrl: string;
};

const mediaType = 'picture';

const membersAreaFolder = 'Members Area';

const visitorsUploadsFolderId = 'visitor-uploads';

const dataUrlToBlob = (url: string, flowAPI: FlowApi) =>
  flowAPI.httpClient
    .get<Blob>(url, {
      responseType: 'blob',
    })
    .then(({ data }) => data);

export const uploadImage = async (
  flowAPI: FlowApi,
  fileName: string | undefined,
  fileDataUrl: string,
) => {
  const { data } = await flowAPI.httpClient.get<MediaCredentialsResponse>(
    membersApiPictureURL,
  );
  const { uploadToken, uploadUrl } = data;
  const formData = new FormData();
  const blob = await dataUrlToBlob(fileDataUrl, flowAPI);

  formData.append('directory', membersAreaFolder);
  formData.append('parent_folder_id', visitorsUploadsFolderId);
  formData.append('upload_token', uploadToken);
  formData.append('media_type', mediaType);
  formData.append('file', blob, fileName);

  const response = await flowAPI.httpClient.post<MediaPlatformImage[]>(
    uploadUrl,
    formData,
  );

  const [mediaPicture] = response.data;
  const { width, height, file_name, original_file_name } = mediaPicture;
  const picture = { width, height, file_name, original_file_name };

  await flowAPI.httpClient.post(
    membersApiMediaIndexURL,
    JSON.stringify(picture),
    {
      headers: { 'Content-Type': 'application/json' },
    },
  );

  return picture;
};
