import { WidgetId } from '@wix/members-area-app-definitions';
import type { IMembersAreaWidgetPluginService } from '@wix/members-area-widget-plugin-lib/viewer';
import { MembersAreaEvent } from '@wix/members-area-widget-plugin-lib/viewer';
import { createEventHandler } from '@wix/tpa-settings';
import type Experiments from '@wix/wix-experiments';

import { profileCardAppDefId } from '../../constants';
import type { Store } from '../../store';
import { badgesThunk } from '../../store/slices';
import {
  changeSectionState,
  openSection,
  openTab,
} from '../../store/slices/app-settings/slice';
import { blockMember } from '../../store/slices/member/slice';
import { setIsPublicProfilePreview } from '../../store/slices/ui/slice';
import { initialStateRefetch } from '../../store/thunk';
import type {
  FlowApi,
  MemberSEOData,
  ProfileCardAPI,
  SettingsEvent,
  SettingsSection,
  SettingsSectionState,
  SettingsTab,
  WixCodeApi,
} from '../../types';
import { ProfileChangeEvent } from '../../types';
import { renderSEOTags } from './controller-utils';

export const createSettingsListener = (publicData: Object = {}) => {
  return createEventHandler<SettingsEvent>(publicData);
};

export type SettingsEventHandler = ReturnType<typeof createSettingsListener>;

const registerSettingsListeners = (
  settingsEventHandler: SettingsEventHandler,
  store: Store,
) => {
  settingsEventHandler.on('tabChanged', (settingsTab: SettingsTab) => {
    store.dispatch(openTab(settingsTab));
  });

  settingsEventHandler.on(
    'sectionChanged',
    (settingsSection: SettingsSection) => {
      store.dispatch(openSection(settingsSection));
    },
  );

  settingsEventHandler.on(
    'sectionStateChanged',
    (settingsSectionState: SettingsSectionState) => {
      store.dispatch(changeSectionState(settingsSectionState));
    },
  );
};

const registerProfileCardListeners = async (
  { controllerConfig }: FlowApi,
  store: Store,
) => {
  const { site } = controllerConfig.wixCodeApi;
  const profileCardAPI = (await site.getPublicAPI(
    profileCardAppDefId,
  )) as null | ProfileCardAPI;

  profileCardAPI?.registerToProfileChange?.((payload) => {
    switch (payload.event) {
      case ProfileChangeEvent.BadgeAssigned:
        store.dispatch(
          badgesThunk.fetchAssignedBadges(payload.assignedBadgeIds),
        );
        return;
      case ProfileChangeEvent.MemberBlocked:
        store.dispatch(blockMember());
        return;
      case ProfileChangeEvent.PublicProfilePreviewChanged:
        store.dispatch(
          setIsPublicProfilePreview(payload.isPublicProfilePreview),
        );
        return;
    }
  });
};

export const registerControllerListeners = async (
  flowAPI: FlowApi,
  settingsEventHandler: SettingsEventHandler,
  store: Store,
) => {
  const { isEditor, isSSR, isViewer } = flowAPI.environment;

  if (isEditor) {
    registerSettingsListeners(settingsEventHandler, store);
  }

  if (isViewer && !isSSR) {
    return registerProfileCardListeners(flowAPI, store);
  }
};

export const registerUserListeners = (
  flowAPI: FlowApi,
  store: Store,
  isWidgetPlugin: boolean,
) => {
  const { appParams, wixCodeApi, setProps } = flowAPI.controllerConfig;

  wixCodeApi.site.onInstanceChanged(
    ({ instance }) => setProps({ instance }),
    appParams.appDefinitionId,
  );

  if (!isWidgetPlugin) {
    wixCodeApi.user.onLogin(async () => {
      store.dispatch(initialStateRefetch());
    });
  }
};

export const registerWidgetPluginHostListeners = ({
  widgetPluginService,
  store,
  wixCodeApi,
  isViewer,
  seoData,
  experiments,
}: {
  wixCodeApi: WixCodeApi;
  isViewer: boolean;
  widgetPluginService: IMembersAreaWidgetPluginService;
  store: Store;
  seoData: MemberSEOData | null;
  experiments: Experiments;
}) => {
  if (!widgetPluginService.getIsWidgetPlugin()) {
    return;
  }

  widgetPluginService.onMembersAreaEvent(async (event) => {
    switch (event.type) {
      case MembersAreaEvent.MemberChanged:
        return void store.dispatch(initialStateRefetch());
      case MembersAreaEvent.TabChanged:
        const shouldCallRenderSEOTags =
          isViewer && WidgetId.About === event.payload && !!seoData;

        if (shouldCallRenderSEOTags) {
          const { site } = store.getState();
          await renderSEOTags({ wixCodeApi, seoData, site, experiments });
        }
    }
  });
};
