import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import settingsParams from '../../components/BookOnline/settingsParams';
import {
  DisplayServicesByOptions,
  FilterOption,
  FilterServicesByOptions,
} from '../../types/types';
import { BookingsAPI } from '../../api/BookingsApi';
import {
  BookingsQueryParams,
  getUrlQueryParamValue,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import {
  ALL_SERVICES,
  REQUESTED_CATEGORIES_URL_PARAM_NAME,
} from '../../consts';
import { ReservedLocationIds } from '@wix/bookings-uou-types';
import { ServiceListSettings } from '../../../legacy/appSettings/appSettings';

export async function getFilterOptions({
  flowAPI,
  bookingsApi,
  appSettings,
}: {
  flowAPI: ControllerFlowAPI;
  bookingsApi: BookingsAPI;
  appSettings?: ServiceListSettings;
}): Promise<FilterOption[]> {
  const {
    settings,
    translations: { t },
  } = flowAPI;

  if (!isListFilterVisible({ flowAPI, appSettings })) {
    return [];
  }

  const filterServicesBy = appSettings?.CATEGORIES_TYPE
    ? appSettings.CATEGORIES_TYPE
    : settings.get(settingsParams.filterServicesBy);

  let filterOptions: FilterOption[];
  try {
    if (filterServicesBy === FilterServicesByOptions.LOCATIONS) {
      const { customerLocations, businessLocations, customLocations } =
        await bookingsApi.queryLocations();
      const locations =
        businessLocations?.locations
          ?.map((location) => ({
            id: location.id!,
            title: location.business?.name!,
            isSelected: false,
          }))
          ?.sort(sortLocations) ?? [];

      const displayServiceByLocation = appSettings?.FILTER_BY
        ? appSettings?.FILTER_BY === DisplayServicesByOptions.BY_LOCATIONS
        : settings.get(settingsParams.displayServicesBy) ===
          DisplayServicesByOptions.BY_LOCATIONS;

      const filteredLocationIds = displayServiceByLocation
        ? appSettings?.SELECTED_LOCATIONS
          ? (appSettings?.SELECTED_LOCATIONS as string[])
          : (settings.get(settingsParams.selectedLocations) as string[])
        : [];

      const filteredLocations = filteredLocationIds?.length
        ? locations.filter((location) =>
            filteredLocationIds.includes(location.id),
          )
        : locations;

      const hasOtherLocations =
        customLocations?.exists || customerLocations?.exists;

      filterOptions = hasOtherLocations
        ? [
            ...filteredLocations,
            {
              id: ReservedLocationIds.OTHER_LOCATIONS,
              title: t(
                'service-list.categories.location-categories.other-locations',
              ),
              isSelected: false,
            },
          ]
        : filteredLocations;
    } else {
      const categories = await bookingsApi.queryCategories();
      filterOptions =
        categories?.map((category) => ({
          id: category.id!,
          title: category.name!,
          isSelected: false,
        })) ?? [];
    }
  } catch (error) {
    console.error(error);
    filterOptions = [];
  }

  if (
    isShowAllServicesFilterOptionVisible({
      appSettings,
      flowAPI,
      filterOptions,
    })
  ) {
    filterOptions.unshift({
      id: ALL_SERVICES,
      title: appSettings?.CATEGORY_ALL_SERVICES_TEXT
        ? (appSettings.CATEGORY_ALL_SERVICES_TEXT as string)
        : settings.get(settingsParams.allServices),
      isSelected: true,
    });
  } else if (filterOptions.length > 0) {
    filterOptions[0].isSelected = true;
  }

  const preSelectedCategory = getUrlQueryParamValue(
    flowAPI.controllerConfig.wixCodeApi,
    REQUESTED_CATEGORIES_URL_PARAM_NAME as BookingsQueryParams,
  );

  const hasCategoryDeepLink = !!preSelectedCategory;

  const preSelectedLocation = getUrlQueryParamValue(
    flowAPI.controllerConfig.wixCodeApi,
    BookingsQueryParams.LOCATION,
  );

  const hasLocationDeepLink = !!preSelectedLocation;

  if (
    hasCategoryDeepLink &&
    filterServicesBy === FilterServicesByOptions.CATEGORIES
  ) {
    choosePreselectedFilter(filterOptions, preSelectedCategory);
  } else if (
    hasLocationDeepLink &&
    filterServicesBy === FilterServicesByOptions.LOCATIONS
  ) {
    choosePreselectedFilter(filterOptions, preSelectedLocation);
  }
  return filterOptions;
}

const choosePreselectedFilter = (
  filterOptions: FilterOption[],
  preSelectedFilterId: string,
) => {
  filterOptions.forEach((filterOption) => {
    filterOption.isSelected = filterOption.id === preSelectedFilterId;
  });
  if (
    filterOptions.every((filterOption) => !filterOption.isSelected) &&
    filterOptions.length > 0
  ) {
    filterOptions[0].isSelected = true;
  }
};

const sortLocations = (
  location1: { title: string; id: string },
  location2: { title: string; id: string },
) => {
  if (location1.id === ReservedLocationIds.OTHER_LOCATIONS) {
    return 1;
  }
  if (location2.id === ReservedLocationIds.OTHER_LOCATIONS) {
    return -1;
  }
  return location1.title > location2.title ? 1 : -1;
};

export const isShowAllServicesFilterOptionVisible = ({
  appSettings,
  flowAPI,
  filterOptions,
}: {
  appSettings?: ServiceListSettings;
  flowAPI: ControllerFlowAPI;
  filterOptions?: FilterOption[];
}): boolean =>
  (appSettings?.CATEGORY_ALL_SERVICES_SHOW
    ? !!appSettings.CATEGORY_ALL_SERVICES_SHOW
    : flowAPI.settings.get(settingsParams.showAllServicesFilterOption)) &&
  (filterOptions ? filterOptions.length > 1 : true);

export const isListFilterVisible = ({
  appSettings,
  flowAPI,
}: {
  appSettings?: ServiceListSettings;
  flowAPI: ControllerFlowAPI;
}): boolean =>
  appSettings
    ? !!appSettings.DISPLAY_CATEGORIES
    : flowAPI.settings.get(settingsParams.isListFilterVisible);
