import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import {
  createFilterViewModels,
  FilterViewModel,
  memoizedFiltersViewModel,
} from '../filterViewModel/filterViewModel';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { CalendarState } from '../../controller';
import { Optional } from '../../../../types/types';
import { MemoizedViewModalFactory } from '../viewModel';
import {
  createTimezoneSelectionViewModel,
  memoizedTimezoneSelectionViewModel,
  TimezoneSelectionViewModel,
} from '../timezoneSelectionViewModel/timezoneSelectionViewModel';
import { isCalendarPage, isCalendarWidget } from '../../../../utils/presets';

export type HeaderViewModel = {
  title?: string;
  subtitle?: string;
  serviceTagLine?: string;
  customSubtitle?: string;
  filters: FilterViewModel[];
  timezoneSelectionViewModel?: TimezoneSelectionViewModel;
};

export const memoizedHeaderViewModel: MemoizedViewModalFactory<
  Optional<HeaderViewModel>
> = {
  dependencies: {
    state: ['servicesInView'],
    settings: ['headerSubtitleSource', 'headerSubtitle', 'headerTitle'],
    subDependencies: [
      memoizedFiltersViewModel.dependencies,
      memoizedTimezoneSelectionViewModel.dependencies,
    ],
  },
  createViewModel: createHeaderViewModel,
};

export function createHeaderViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): Optional<HeaderViewModel> {
  const {
    settingsParams,
    getContent,
    preset,
    isDateAndTimeViewMode,
    experiments,
    t,
  } = context;
  const { servicesInView } = state;
  const isShowTitleInDateAndTimeViewModeEnabled = experiments.enabled(
    'specs.bookings.showTitlesInDateAndTimeViewMode',
  );

  const isCalendar = isCalendarPage(preset) || isCalendarWidget(preset);

  let title: string | undefined;
  const isTitleVisible = !isDateAndTimeViewMode;

  if (isTitleVisible) {
    title = isCalendar
      ? servicesInView[0].name!
      : getContent({
          settingsParam: settingsParams.headerTitle,
          translationKey: 'app.settings.defaults.header-title',
        });
  }

  if (isShowTitleInDateAndTimeViewModeEnabled && isDateAndTimeViewMode) {
    title = t('app.date-and-time-view-mode.title');
  }

  let subtitle;
  let serviceTagLine;
  let customSubtitle;
  const isSubtitleVisible =
    (!isDateAndTimeViewMode || isShowTitleInDateAndTimeViewModeEnabled) &&
    isCalendar;

  if (isSubtitleVisible) {
    serviceTagLine = servicesInView[0].tagLine ?? undefined;
    customSubtitle = getContent({
      settingsParam: settingsParams.headerSubtitle,
      translationKey: 'app.settings.defaults.header-subtitle',
    });
  }

  const filters = !isDateAndTimeViewMode
    ? createFilterViewModels({ state, context })
    : [];

  const isTimezoneSelectionVisible = !isCalendar;

  const timezoneSelectionViewModel = isTimezoneSelectionVisible
    ? createTimezoneSelectionViewModel({
        state,
        context,
      })
    : undefined;

  const isAtLeastOneHeaderElementExists =
    !!title ||
    !!(serviceTagLine || customSubtitle) ||
    filters.length ||
    !!timezoneSelectionViewModel;

  if (isAtLeastOneHeaderElementExists) {
    return {
      title,
      subtitle,
      serviceTagLine,
      customSubtitle,
      filters: filters!,
      timezoneSelectionViewModel,
    };
  }
}
