import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ServicePageViewModel } from '../../../../service-page-view-model/servicePageViewModel';

export type OnPreferencesModalServiceSelected = (
  selectedService: Service,
) => void;

export const createOnPreferencesModalServiceSelectedAction = ({
  viewModel,
  flowAPI,
}: {
  viewModel: ServicePageViewModel;
  flowAPI: ControllerFlowAPI;
}): OnPreferencesModalServiceSelected => {
  const {
    controllerConfig: { setProps },
    httpClient,
  } = flowAPI;
  return async (selectedService) => {
    const { onServiceSelected } = await import(
      '@wix/bookings-services-preferences-modal/controllerActions'
    );

    onServiceSelected({
      selectedService,
      httpClient,
      getServicesPreferencesModalData: () => viewModel.dialogViewModel.data!,
      setServicesPreferencesModalData: (data) => {
        viewModel.dialogViewModel.data = data;
        setProps({ viewModel: { ...viewModel } });
      },
    });
  };
};
