import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { Adapter } from '@wix/app-settings-client/dist/src/domain';
import {
  mergeAppSettingsToSettingsParams,
  mergeAppSettingsToStyleParams,
  ServiceListSettings,
} from './appSettings';
import { appClient, Scope } from '@wix/app-settings-client';
import {
  SINGLE_SERVICE_EDITOR_X_PRESET_ID,
  SINGLE_SERVICE_PRESET_ID,
} from '../appKeys/DefaultSettings';
import { cleanNulls, mergeOpacityToColor } from '../utils';
import { FilterType, SettingsKeys, WidgetData } from '../types';
import {
  EnrichedService,
  ServicesPagingMetadata,
  ViewMode,
} from '../../src/types/types';
import { AppSettingsColor } from './mergeColors';
import { isRunningInIframe } from '@wix/bookings-catalog-calendar-viewer-utils';
import { createWidgetViewModel } from '../../src/viewModel/viewModel';
import { ServiceListContext } from '../../src/context/createServiceListContext';
import { Location } from '@wix/ambassador-bookings-services-v2-service/types';
import { BookingsAPI } from '../../src/api/BookingsApi';
import { getAdditionalServicesData } from '../../src/actions/getAdditionalServicesData/getAdditionalServicesData';
import { getFilterOptions } from '../../src/utils/filterOptions/getFilterOptions';

export const getAppSettingsClient = (flowAPI: ControllerFlowAPI) => {
  const { controllerConfig } = flowAPI;
  const { config, appParams } = controllerConfig;
  let appSettings;

  if (flowAPI.environment.isEditor && isRunningInIframe()) {
    appSettings = appClient({ scope: Scope.COMPONENT });
  } else {
    appSettings = appClient({
      scope: Scope.COMPONENT,
      adapter: new AppSettingsClientAdapter({
        appDefId: appParams.appDefinitionId,
        instanceId: appParams.instanceId,
        externalId: config.externalId || '',
      }),
    });
  }
  return appSettings;
};

export const getUserSettings = async (
  appSettings: any,
  presetId: string,
  userData?: WidgetData,
) => {
  let userSettings: ServiceListSettings =
    userData?.config.settings || (await appSettings.getAll());
  userSettings = cleanNulls(userSettings);
  if (
    userSettings &&
    (presetId === SINGLE_SERVICE_PRESET_ID ||
      presetId === SINGLE_SERVICE_EDITOR_X_PRESET_ID) &&
    !userSettings.SELECTED_RESOURCES
  ) {
    userSettings.SELECTED_RESOURCES = {
      categories: [],
      offerings: [],
      filter: FilterType.FIRST,
    };
  }
  return userSettings;
};

export const updatePublicData = async ({
  dangerousPublicDataOverride,
  dangerousStylesOverride,
  newUserStylesSettings,
  newUserSettings,
  presetId,
  scale,
  viewMode,
  flowAPI,
  shouldWorkWithAppSettings,
  services,
  businessLocations,
  serviceListContext,
  bookingsApi,
  servicesPagingMetadata,
}: {
  newUserSettings: ServiceListSettings;
  presetId: string;
  flowAPI: ControllerFlowAPI;
  dangerousStylesOverride: Function;
  dangerousPublicDataOverride: Function;
  scale: number;
  viewMode: ViewMode;
  newUserStylesSettings?: any;
  shouldWorkWithAppSettings: boolean;
  businessLocations: Location[];
  services: EnrichedService[];
  serviceListContext: ServiceListContext;
  bookingsApi: BookingsAPI;
  servicesPagingMetadata: ServicesPagingMetadata;
}) => {
  const { controllerConfig } = flowAPI;
  const { setProps, config } = controllerConfig;
  newUserSettings = cleanNulls(newUserSettings);
  const userStylesColorsWithOpacity = {};
  if (newUserStylesSettings) {
    (Object.keys(newUserStylesSettings.colors || {}) as SettingsKeys[]).forEach(
      (colorKey) => {
        // @ts-expect-error
        userStylesColorsWithOpacity[colorKey] = {
          ...newUserStylesSettings.colors[colorKey],
          value: newUserSettings[colorKey]
            ? mergeOpacityToColor(
                (newUserSettings[colorKey] as AppSettingsColor).value,
                newUserStylesSettings.colors[colorKey].value,
              )
            : newUserStylesSettings.colors[colorKey].value,
        };
      },
    );
  }
  const stylesProp = dangerousStylesOverride(
    mergeAppSettingsToStyleParams(
      {
        ...newUserSettings,
        ...userStylesColorsWithOpacity,
        ...(newUserStylesSettings ? newUserStylesSettings.fonts : {}),
      },
      {
        booleans: {},
        numbers: {},
        googleFontsCssUrl: '',
      },
      presetId,
    ),
  );
  const publicData = dangerousPublicDataOverride(
    mergeAppSettingsToSettingsParams(
      newUserSettings,
      config.publicData,
      presetId,
    ),
  );

  const filterOptions = await getFilterOptions({
    flowAPI,
    bookingsApi,
    appSettings: newUserSettings,
  });

  const widgetViewModel = await createWidgetViewModel({
    scale,
    serviceListContext,
    flowAPI,
    viewMode,
    shouldWorkWithAppSettings,
    businessLocations,
    allServices: services,
    appSettings: newUserSettings,
    servicesPagingMetadata,
    filterOptions,
  });

  const { coursesAvailability, services: populatedServices } =
    await getAdditionalServicesData({
      bookingsApi,
      services: widgetViewModel.services,
      isServiceListEventsClassDaysEnable: flowAPI.experiments.enabled(
        'specs.bookings.ServiceListEventsClassDays',
      ),
    });

  widgetViewModel.coursesAvailability = coursesAvailability;
  widgetViewModel.services = populatedServices;

  setProps({
    widgetViewModel,
    ...stylesProp,
    ...publicData,
  });
};

class AppSettingsClientAdapter implements Adapter {
  appDefId;
  instanceId;
  externalId;
  signedInstance: any;
  constructor({ appDefId, instanceId, externalId }: any) {
    this.appDefId = appDefId;
    this.instanceId = instanceId;
    this.externalId = externalId;
  }

  getAppDefId(): string {
    return this.appDefId;
  }

  getInstanceId(): string {
    return this.instanceId;
  }

  async getExternalId(): Promise<string> {
    return this.externalId;
  }

  async setExternalId(id: string): Promise<void> {}

  triggerSettingsUpdated(data: any, scope: any): void {}

  onSettingsUpdated(scope: any, cb: (k: object) => void): void {}
}
