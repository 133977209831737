import { SortOrder } from '@wix/ambassador-bookings-reader-v2-extended-booking/types';
import {
  addOrder,
  bookingToBookingDTO,
  isBookingPaidUsingMembership,
} from '../../../utils/mappers/bookings-mapper/bookings-mapper';
import { ApiContext } from '../../types';
import {
  AllowedActions,
  ApplicationState,
  BookingDTO,
  Bookings,
  BookingsGroup,
  BusinessInfo,
  SelectedTimezone,
  TabTypes,
} from '../../../types';
import { getServiceIdFromExtendedBooking } from '../../../utils/bookInfo';
import _ from 'lodash';
import { getSelectedTimezone } from './getSelectedtTmezone';
import { getBusinessInfo } from './getBusinessInfo';

export interface GetEnrichedBookingsArgs {
  type: TabTypes;
  applicationState: ApplicationState;
  withBookingPolicySettings?: boolean;
}

export interface EnrichedBookings {
  bookings: Bookings;
  selectedTimezone: SelectedTimezone;
  businessInfo: BusinessInfo;
}

export const getEnrichedBookings = async ({
  flowAPI,
  type,
  api,
  applicationState,
  withBookingPolicySettings = false,
}: GetEnrichedBookingsArgs & ApiContext): Promise<EnrichedBookings> => {
  const {
    businessInfo,
    userInfo,
    selectedTimezone: selectedTimezoneState,
  } = applicationState;

  const contactId = applicationState.userInfo?.contactId!;

  // Query Bookings
  const [bookings, businessInfoResponse] = await Promise.all([
    type === TabTypes.UPCOMING
      ? api.queryBookings({
          contactId,
          sortOrder: SortOrder.ASC,
          endDateComparisonOperator: '$gt',
          withBookingConferencingDetails: true,
          withBookingPolicySettings,
        })
      : api.queryBookings({
          contactId,
          sortOrder: SortOrder.DESC,
          endDateComparisonOperator: '$lte',
        }),
    businessInfo ? Promise.resolve(businessInfo) : getBusinessInfo(api),
  ]);

  const selectedTimezone =
  selectedTimezoneState ||
  getSelectedTimezone({
    businessInfo: businessInfoResponse,
    userInfo,
  });

  if (!bookings.extendedBookings?.length) {
    return {
      bookings: {
        groups: [],
      },
      selectedTimezone,
      businessInfo: businessInfoResponse,
    };
  }

  const groupIds: string[] = _.uniq(
    bookings?.extendedBookings
      ?.filter((booking) => booking?.booking?.multiServiceBookingInfo?.id)
      .map((booking) => booking?.booking?.multiServiceBookingInfo?.id!),
  );

  const serviceIds = bookings?.extendedBookings?.map((booking) =>
    getServiceIdFromExtendedBooking(booking),
  );

  const shouldGetPricingPlans =
    type === TabTypes.UPCOMING &&
    bookings?.extendedBookings?.some((booking) =>
      isBookingPaidUsingMembership(booking.booking!),
    );

  const shouldGetOrders = type === TabTypes.UPCOMING && bookings?.extendedBookings?.length;

  // Query APIs
  const [
    servicesResponse,
    allowedActionsResponse,
    ordersResponse,
    purchasedPlans,
  ] = await Promise.all([
    serviceIds?.length
      ? api.queryServices({ serviceIds })
      : Promise.resolve(null),
    groupIds?.length
      ? api.getGroupAllowedActions({ groupIds })
      : Promise.resolve(null),
    shouldGetOrders
      ? api.getOrders({ bookings })
      : Promise.resolve({ orders: [] }),
    shouldGetPricingPlans
      ? api.getPurchasedPricingPlans({ contactId })
      : Promise.resolve([]),
  ]);

  const { orders = [] } = ordersResponse;

  const bookingsDTO: BookingDTO[] | undefined = bookings?.extendedBookings?.map(
    (booking) =>
      bookingToBookingDTO({
        bookingEntry: booking,
        services: servicesResponse || [],
        businessAddress: businessInfoResponse?.formattedAddress,
        purchasedPlans,
        flowAPI,
      }),
  );

  // Create Groups with allowed actions and staff From bookingsDTO
  const groupAllowedActions: { [x: string]: AllowedActions } = {};
  const groups: { [key: string]: BookingsGroup } = {};

  if (allowedActionsResponse) {
    allowedActionsResponse?.results?.forEach((allowedAction) => {
      if (allowedAction?.itemMetadata?.id && allowedAction.item) {
        groupAllowedActions[allowedAction.itemMetadata.id] = allowedAction.item;
      }
    });
  }

  bookingsDTO?.forEach((booking) => {
    const groupId = booking.groupId || booking.bookingId;

    if (!groups[groupId]) {
      groups[groupId] = {
        bookings: [],
        ...(booking.groupId ? { groupId: booking.groupId } : {}),
        allowedActions: booking.groupId
          ? groupAllowedActions[groupId]
          : booking.allowedActions,
      };
    }

    groups[groupId].bookings.push({ ...booking });
  });

  // Sort Groups by Type
  const sortedGroups =
    Object.values(groups).sort((a, b) =>
      type === TabTypes.UPCOMING
        ? a.bookings[0].start - b.bookings[0].start
        : b.bookings[0].start - a.bookings[0].start,
    ) || [];

  // Add order to each group
  const enrichedBookings = addOrder({
    bookings: {
      groups: sortedGroups,
    },
    orders,
  });

  return {
    bookings: enrichedBookings,
    selectedTimezone,
    businessInfo: businessInfoResponse,
  };
};
