import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ServiceListSettings } from '../../../legacy/appSettings/appSettings';
import {
  BookingsQueryParams,
  getUrlQueryParamValue,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import {
  DisplayServicesByOptions,
  FilterServicesByOptions,
} from '../../types/types';
import { FilterType, SelectedResources } from '../../../legacy/types';
import {
  ALL_SERVICES,
  SINGLE_SERVICE_EDITOR_X_PRESET_ID,
  SINGLE_SERVICE_PRESET_ID,
} from '../../consts';
import { getPresetId } from '../../components/BookOnline/controllerPrePageReady';
import settingsParams from '../../components/BookOnline/settingsParams';
import { QueryServicesFilter } from '../../api/BookingsApi';

export const buildQueryServicesFilter = ({
  flowAPI,
  shouldWorkWithAppSettings,
  appSettings,
  ignorePreSelectedCategories,
  ignorePreSelectedLocations,
  selectedFilterOptionId,
}: {
  shouldWorkWithAppSettings: boolean;
  appSettings?: ServiceListSettings;
  flowAPI: ControllerFlowAPI;
  ignorePreSelectedCategories?: boolean;
  ignorePreSelectedLocations?: boolean;
  selectedFilterOptionId?: string;
}): QueryServicesFilter => {
  const {
    controllerConfig: { wixCodeApi, config },
    environment: { isEditorX },
    settings,
  } = flowAPI;

  const shouldFilterServicesOnClient =
    shouldWorkWithAppSettings && flowAPI.environment.isEditor;

  if (shouldFilterServicesOnClient) {
    return {};
  }

  const filter: QueryServicesFilter = {};
  const preSelectedResource = getUrlQueryParamValue(
    wixCodeApi,
    BookingsQueryParams.RESOURCE,
  );

  if (preSelectedResource) {
    filter.staffMemberIds = [preSelectedResource];
  }

  const returnArrayIfLengthExists = <T extends any[]>(arr: T) =>
    arr.length > 0 ? arr : undefined;

  const preSelectedCategory = getUrlQueryParamValue(
    wixCodeApi,
    BookingsQueryParams.CATEGORY,
  );

  const preSelectedLocation = getUrlQueryParamValue(
    wixCodeApi,
    BookingsQueryParams.LOCATION,
  );

  const buildQueryServicesFilterWithSelectedOption = () => {
    const filterWithSelectedOption: QueryServicesFilter = {};

    const filterServicesBy =
      appSettings?.CATEGORIES_TYPE ||
      settings.get(settingsParams.filterServicesBy);

    if (
      preSelectedCategory &&
      preSelectedCategory !== ALL_SERVICES &&
      !ignorePreSelectedCategories
    ) {
      filterWithSelectedOption.categoryIds = [preSelectedCategory];
    } else if (
      selectedFilterOptionId &&
      selectedFilterOptionId !== ALL_SERVICES &&
      filterServicesBy === FilterServicesByOptions.CATEGORIES
    ) {
      filterWithSelectedOption.categoryIds = [selectedFilterOptionId];
    }

    if (preSelectedLocation && !ignorePreSelectedLocations) {
      filterWithSelectedOption.locationIds = [preSelectedLocation];
    } else if (
      selectedFilterOptionId &&
      selectedFilterOptionId !== ALL_SERVICES &&
      filterServicesBy === FilterServicesByOptions.LOCATIONS
    ) {
      filterWithSelectedOption.locationIds = [selectedFilterOptionId];
    }

    return filterWithSelectedOption;
  };

  if (shouldWorkWithAppSettings) {
    const filterBy = appSettings?.FILTER_BY;
    if (filterBy === DisplayServicesByOptions.BY_LOCATIONS) {
      const selectedLocations = appSettings?.SELECTED_LOCATIONS as string[];
      filter.locationIds = returnArrayIfLengthExists(selectedLocations);
    } else {
      const selectedResources =
        (appSettings?.SELECTED_RESOURCES as SelectedResources) || {};

      const { categories, filter: filterType, offerings } = selectedResources;

      if (filterType === FilterType.FIRST) {
        filter.limit = 1;
        return filter;
      } else if (filterType === FilterType.SPECIFIC) {
        filter.categoryIds = returnArrayIfLengthExists(categories);
        filter.serviceIds = returnArrayIfLengthExists(offerings);
      }
    }

    return { ...filter, ...buildQueryServicesFilterWithSelectedOption() };
  }

  if (
    [SINGLE_SERVICE_PRESET_ID, SINGLE_SERVICE_EDITOR_X_PRESET_ID].includes(
      getPresetId(config, isEditorX),
    )
  ) {
    const selectedService = settings.get(settingsParams.selectedService);
    if (selectedService) {
      filter.serviceIds = [selectedService];
    } else {
      filter.limit = 1;
    }

    return filter;
  }

  const displayServicesBy = settings.get(settingsParams.displayServicesBy);

  if (displayServicesBy === DisplayServicesByOptions.BY_SERVICES) {
    const selectedCategories = settings.get(settingsParams.selectedCategories);

    filter.categoryIds = returnArrayIfLengthExists(selectedCategories);
  } else if (
    displayServicesBy === DisplayServicesByOptions.BY_SPECIFIC_SERVICES
  ) {
    const selectedServices = settings.get(settingsParams.selectedServices);

    filter.serviceIds = returnArrayIfLengthExists(selectedServices);
  } else if (displayServicesBy === DisplayServicesByOptions.BY_LOCATIONS) {
    const selectedLocations = settings.get(settingsParams.selectedLocations);
    filter.locationIds = returnArrayIfLengthExists(selectedLocations);
  }

  return { ...filter, ...buildQueryServicesFilterWithSelectedOption() };
};
