import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getLocaleDatasetResource } from '../../../components/MyAccount/i18n';
import { getCountriesData } from '../../../server/account.api';
import type { ThunkApiConfig } from '../../../types';
import { FieldSection } from '../../../types';
import { I18nNamespace } from '../../../types/i18n';

type State = {
  countriesData: {
    id: string;
    value: string;
    states: {
      id: string;
      value: string;
    }[];
  }[];
};

const fetchLocaleData = createAsyncThunk<
  State['countriesData'] | undefined,
  void,
  ThunkApiConfig
>('locale/fetchLocaleData', async (_, { extra, getState }) => {
  const state = getState();
  const hasAddressFields = state.fields.some(
    (field) => field.section === FieldSection.ADDRESS,
  );
  if (!hasAddressFields) {
    return;
  }
  const { flowAPI } = extra;
  const { i18n } = flowAPI.translations;
  const [countriesDataResponse, i18nResource] = await Promise.all([
    flowAPI.httpClient.request(getCountriesData()),
    getLocaleDatasetResource(flowAPI),
  ]);
  const countriesData = countriesDataResponse.data;

  i18n.addResourceBundle(
    i18nResource.lng,
    i18nResource.ns,
    i18nResource.resources,
  );

  const t = i18n.getFixedT(
    i18n.options.lng ?? 'en',
    I18nNamespace.localeDataset,
  );

  return countriesData
    .filter(({ displayName }) => displayName !== t(displayName))
    .map((countryData) => ({
      id: countryData.key,
      value: t(countryData.displayName),
      isSelectable: true,
      states: countryData.states
        .filter(({ displayName }) => displayName !== t(displayName))
        .map((subdivision) => ({
          id: `${countryData.key}-${subdivision.key}`,
          value: t(subdivision.displayName),
          isSelectable: true,
        }))
        .sort((a, b) => a.value.localeCompare(b.value)),
    }))
    .sort((a, b) => a.value.localeCompare(b.value));
});

export const localeSlice = createSlice({
  name: 'locale',
  initialState: {
    countriesData: [],
  } as State,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLocaleData.fulfilled, (state, { payload }) => {
      if (payload) {
        state.countriesData = payload;
      }
    });
  },
});

export const localeThunk = { fetchLocaleData };
