import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import classNames from 'classnames';
import React, { type FC } from 'react';
import { Toast as TPAToast, ToastPlacement } from 'wix-ui-tpa/cssVars';

import { useWidget } from '../../../contexts/widgets/hooks';
import { getToastContent } from './getToastContent';
import classes from './Toast.scss';

const Toast: FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { ui, handlers } = useWidget();
  const { hideToast } = handlers;

  const { toast } = ui;

  const { toastContent, toastSkin } = getToastContent(toast.type, t);

  return (
    <TPAToast
      className={classNames({
        [classes.toast]: true,
        [classes.displayNone]: !toast.isVisible,
        [classes.mobile]: isMobile,
      })}
      skin={toastSkin}
      children={toastContent}
      placement={
        isMobile ? ToastPlacement.bottomFullWidth : ToastPlacement.inline
      }
      isShown={toast.isVisible}
      shouldShowCloseButton={true}
      onClose={() => hideToast()}
    />
  );
};

export default Toast;
