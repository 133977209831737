import React, { FC } from 'react';
import { Text, TextTypography as TYPOGRAPHY } from 'wix-ui-tpa/cssVars';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { st, classes } from './EmptyState.st.css';
import { DataHooks } from './consts';

export type EmptyStateProps = {};

export const EmptyState: FC<EmptyStateProps> = () => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const isServiceListImageCornenrRadiusEnabled = experiments.enabled(
    'specs.bookings.serviceListImageCornerRadius',
  );

  return (
    <div
      data-hook={DataHooks.WRAPPER}
      className={st(classes.root, {
        isMobile,
        isServiceListImageCornenrRadiusEnabled,
      })}
    >
      <div className={classes.container}>
        <Text
          data-hook={DataHooks.CONTENT}
          typography={TYPOGRAPHY.smallTitle}
          className={classes.content}
        >
          {t('empty-state.text')}
        </Text>
      </div>
    </div>
  );
};
