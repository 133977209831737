import { CreateActionParams } from '../actions';
import { bookingsBookingsFormLoad } from '@wix/bi-logger-wixboost-ugc/v2';
import {
  bookingsUoUBookTwiceModalLoadClicksSrc16Evid1151,
  bookingsUoUFlowWidgetLoadSrc16Evid32,
} from '@wix/bi-logger-bookings-data/v2';
import { FormStatus } from '../../../../types/form-state';
import {
  DoubleBookingActions,
  DoubleBookingComponents,
  DoubleBookingModalTypes,
  ReferralInfo,
} from '../../../../types/biLoggerTypes';
import { FormState } from '../../../../utils/state/types';
import { createInitialState } from '../../../../utils/state/initialStateFactory';
import { WIDGET_NAME } from '../../../../utils/bi/consts';

export type InitializeWidget = () => void;

export function createInitializeWidgetAction({
  flowApi,
  getControllerState,
  internalActions: { calculatePaymentDetails },
  context: {
    biLogger,
    formApi,
    reportError,
    wixSdkAdapter,
    t,
    bookingsDataCapsule,
  },
}: CreateActionParams): InitializeWidget {
  return async () => {
    const [state, setState] = getControllerState();

    try {
      const initialState: FormState = await createInitialState({
        currentState: state,
        wixSdkAdapter,
        bookingsDataCapsule,
        flowApi,
        formApi,
        t,
        reportError,
      });
      const { isCart, isServiceInCart, serviceData } = initialState;

      if (initialState?.errors?.length) {
        console.error(initialState.errors[0]);
      }

      setState({
        ...initialState,
      });

      biLogger?.initialDefaultBiLogger(initialState);

      biLogger?.report(
        bookingsBookingsFormLoad({
          formId: serviceData.formSchema.formId,
          ...(isCart
            ? {
                ...(isServiceInCart
                  ? { referralInfo: ReferralInfo.AddMoreSessions }
                  : {}),
                emptyCart: !isServiceInCart,
              }
            : {}),
          numberOfServices: Object.keys(serviceData.slotServices).length,
        }),
      );

      biLogger?.report(
        bookingsUoUFlowWidgetLoadSrc16Evid32({ widget_name: WIDGET_NAME }),
      );

      setState({
        status: FormStatus.IDLE,
      });

      await calculatePaymentDetails({});

      const existingBooking = await formApi.getExistingBooking({
        slotServices: initialState.serviceData.slotServices,
        contactId: state.memberDetails?.contactId,
      });

      if (existingBooking) {
        setState({
          existingBookingDetails: existingBooking,
        });

        const isMember = !!state.memberDetails?.contactId;

        biLogger?.report(
          bookingsUoUBookTwiceModalLoadClicksSrc16Evid1151({
            component: DoubleBookingComponents.Modal,
            action: DoubleBookingActions.Load,
            modalType: isMember
              ? DoubleBookingModalTypes.Member
              : DoubleBookingModalTypes.Visitor,
          }),
        );
      }
    } catch (e) {
      console.error(e);
      setState({
        status: FormStatus.IDLE,
      });
    }
  };
}
