/* eslint-disable no-unused-expressions */
/* https://github.com/eslint/eslint/issues/12822 */
import type {
  EditorReadyOptions,
  EditorScriptFlowAPI,
  EditorSDK,
} from '../constants/types';

let fedopsInstance: EditorScriptFlowAPI['fedops'];
let sentryMonitor: EditorScriptFlowAPI['sentry'];

export async function initMonitoring(
  editorSDK: EditorSDK,
  options: EditorReadyOptions,
  flowApi: EditorScriptFlowAPI,
) {
  sentryMonitor = flowApi.sentry;
  fedopsInstance = flowApi.fedops;
}

export function interactionStarted(interactionName: string) {
  try {
    fedopsInstance.interactionStarted(interactionName);
  } catch (e) {
    const err = `Failed to start fedops interaction, reason: ${e}`;
    sentryMonitor?.captureException(err);
  }
}

export function interactionEnded(interactionName: string) {
  try {
    fedopsInstance.interactionEnded(interactionName);
  } catch (e) {
    const err = `Failed to end fedops interaction, reason: ${e}`;
    sentryMonitor?.captureException(err);
  }
}

export function interactionFailed(err: string | Error) {
  sentryMonitor?.captureException(err);
}

export function log(message: string) {
  sentryMonitor?.captureMessage(message, 'info' as any);
}

export async function toMonitored<T extends Function>(
  interactionName: string,
  promise: T,
) {
  try {
    interactionStarted(interactionName);
    const response = await promise();
    interactionEnded(interactionName);
    return response;
  } catch (err) {
    interactionFailed(err as string | Error);
    throw err;
  }
}
