import React, { type FC } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import {
  Button,
  ButtonPriority as PRIORITY,
  ButtonSize as SIZE,
} from 'wix-ui-tpa/cssVars';
import { SpinnerWrapper } from '@wix/bookings-viewer-ui';
import settingsParams from '../../../../../settingsParams';
import { ButtonStyleOptions } from '../../../../../../../types/types';
import { useWidgetViewModel } from '../../../../hooks/useWidgetViewModel';
import stylesParams from '../../../../../stylesParams';
import { DataHooks } from './consts';
import { classes, st } from './BookButton.st.css';

export interface BookButtonProps {
  onClick: () => void;
  url: string | undefined;
  loading?: boolean;
  isNoBookFlow: boolean;
  isPendingApprovalFlow: boolean;
  showViewCourse?: boolean;
}

export const BookButton: FC<BookButtonProps> = ({
  onClick,
  url,
  isNoBookFlow,
  isPendingApprovalFlow,
  showViewCourse,
  loading,
}) => {
  const { isMobile } = useEnvironment();
  const { shouldWorkWithAppSettings } = useWidgetViewModel();
  const settings = useSettings();
  const styles = useStyles();
  const { t } = useTranslation();
  const theme = settings.get(settingsParams.bookButtonStyle);

  const isSecondary =
    shouldWorkWithAppSettings &&
    (theme === ButtonStyleOptions.SQUARE_HOLE ||
      theme === ButtonStyleOptions.CIRCLE_HOLE);

  const spinnerDiameter = isMobile
    ? styles.get(stylesParams.mobileBookButtonFontSize)
    : styles.get(stylesParams.bookButtonFont).size!;

  const getContent = () => {
    if (showViewCourse) {
      return settings.get(settingsParams.viewCourseButtonText);
    }
    if (isNoBookFlow) {
      return settings.get(settingsParams.noBookFlowText);
    }
    if (isPendingApprovalFlow) {
      return settings.get(settingsParams.pendingApprovalText);
    }
    return settings.get(settingsParams.bookButtonText);
  };

  return (
    <div
      data-hook={DataHooks.ROOT}
      className={st(classes.root, {
        theme,
        isMobile,
        shouldWorkWithAppSettings,
      })}
    >
      <Button
        data-hook={DataHooks.BUTTON}
        className={st(classes.button)}
        size={SIZE.medium}
        priority={isSecondary ? PRIORITY.secondary : PRIORITY.primary}
        onClick={
          url
            ? (e) => {
                e.stopPropagation();
                e.preventDefault();
                onClick();
              }
            : onClick
        }
        fullWidth={isMobile}
        aria-label={getContent()}
        role="button"
        tabIndex={0}
        disabled={loading}
        upgrade
        {...(url ? { as: 'a', href: url } : undefined)}
      >
        {loading ? (
          <div className={classes.spinnerWrapper}>
            <SpinnerWrapper
              className={classes.spinner}
              diameter={spinnerDiameter}
              data-hook={DataHooks.SPINNER}
            />
          </div>
        ) : (
          getContent()
        )}
      </Button>
    </div>
  );
};
