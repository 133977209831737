import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { WidgetViewModel } from '../viewModel/viewModel';
import {
  createOnFilterOptionSelectedAction,
  OnFilterOptionSelectedAction,
} from './onFilterOptionSelected/onFilterOptionSelected';
import {
  createOnDialogCloseAction,
  OnDialogCloseAction,
} from './onDialogClose/onDialogClose';
import {
  createNavigateToPricingPlanAction,
  NavigateToPricingPlanAction,
} from './navigateToPricingPlans/navigateToPricingPlans';
import {
  createOnPreferencesModalServiceSelectedAction,
  OnPreferencesModalServiceSelected,
} from './onPreferencesModalServiceSelected/onPreferencesModalServiceSelected';
import {
  createNavigateToNextPageAction,
  NavigateToNextPageAction,
} from './navigateToNextPage/navigateToNextPage';
import {
  createOnServiceClickAction,
  OnServiceClickAction,
} from './onServiceClick/onServiceClick';
import { createOnBookFlowStartAttemptAction } from './onBookFlowStartAttempt/onBookFlowStartAttempt';
import { createOpenServicesPreferencesModalAction } from './openServicesPreferencesModal/openServicesPreferencesModal';
import { createOpenPremiumDialogAction } from './openPremiumDialog/openPremiumDialog';
import {
  createOnMoreInfoClickAction,
  OnMoreInfoClickAction,
} from './onMoreInfoClick/onMoreInfoClick';
import {
  createOnActionButtonClickAction,
  OnActionButtonClickAction,
} from './onActionButtonClick/onActionButtonClick';
import { BookingsAPI } from '../api/BookingsApi';
import { EnrichedService } from '../types/types';
import { ServiceListContext } from '../context/createServiceListContext';
import {
  createGetAdditionalServicesData,
  GetAdditionalServicesData,
} from './getAdditionalServicesData/getAdditionalServicesData';
import { GetActiveFeaturesResponse } from '@wix/ambassador-services-catalog-server/types';
import {
  createLoadServicesByPageAction,
  LoadServicesByPageAction,
} from './loadServicesByPage/loadServicesByPage';

export type WidgetActions = {
  onFilterOptionSelected: OnFilterOptionSelectedAction;
  onDialogClose: OnDialogCloseAction;
  navigateToPricingPlan: NavigateToPricingPlanAction;
  onPreferencesModalServiceSelected: OnPreferencesModalServiceSelected;
  onServiceClick: OnServiceClickAction;
  onMoreInfoClick: OnMoreInfoClickAction;
  onActionButtonClick: OnActionButtonClickAction;
  navigateToNextPage: NavigateToNextPageAction;
  getAdditionalServicesData: GetAdditionalServicesData;
  loadServicesByPage: LoadServicesByPageAction;
};

export const createWidgetActions = ({
  widgetViewModel,
  bookingsApi,
  flowAPI,
  setProps,
  services,
  serviceListContext,
  activeFeatures,
  isPricingPlanInstalled,
}: {
  services: EnrichedService[];
  widgetViewModel: WidgetViewModel;
  bookingsApi: BookingsAPI;
  flowAPI: ControllerFlowAPI;
  setProps: Function;
  serviceListContext: ServiceListContext;
  activeFeatures: GetActiveFeaturesResponse;
  isPricingPlanInstalled: boolean;
}): WidgetActions => {
  const onFilterOptionSelected = createOnFilterOptionSelectedAction({
    serviceListContext,
    activeFeatures,
    isPricingPlanInstalled,
    widgetViewModel,
    services,
    setProps,
    flowAPI,
    bookingsApi,
  });

  const onDialogClose = createOnDialogCloseAction({
    widgetViewModel,
    setProps,
  });

  const navigateToPricingPlan = createNavigateToPricingPlanAction(flowAPI);

  const onPreferencesModalServiceSelected =
    createOnPreferencesModalServiceSelectedAction({
      flowAPI,
      widgetViewModel,
      setProps,
    });

  const navigateToNextPage = createNavigateToNextPageAction({
    flowAPI,
    widgetViewModel,
    serviceListContext,
  });

  const openServicesPreferencesModal = createOpenServicesPreferencesModalAction(
    {
      setProps,
      widgetViewModel,
      flowAPI,
      serviceListContext,
    },
  );

  const openPremiumDialog = createOpenPremiumDialogAction({
    setProps,
    widgetViewModel,
  });

  const onBookFlowStartAttempt = createOnBookFlowStartAttemptAction({
    flowAPI,
    bookingsApi,
    openServicesPreferencesModal,
    navigateToNextPage,
    openPremiumDialog,
    serviceListContext,
  });

  const onServiceClick = createOnServiceClickAction({
    navigateToNextPage,
    flowAPI,
    onBookFlowStartAttempt,
  });

  const onMoreInfoClick = createOnMoreInfoClickAction({
    navigateToNextPage,
  });

  const onActionButtonClick = createOnActionButtonClickAction({
    navigateToNextPage,
    onBookFlowStartAttempt,
    flowAPI,
    serviceListContext,
  });

  const isServiceListEventsClassDaysEnable = flowAPI.experiments.enabled(
    'specs.bookings.ServiceListEventsClassDays',
  );

  const getAdditionalServicesData = createGetAdditionalServicesData({
    bookingsApi,
    setProps,
    widgetViewModel,
    isServiceListEventsClassDaysEnable,
  });

  const loadServicesByPage = createLoadServicesByPageAction({
    widgetViewModel,
    bookingsApi,
    flowAPI,
    activeFeatures,
    isPricingPlanInstalled,
    serviceListContext,
    setProps,
  });

  return {
    navigateToNextPage,
    onFilterOptionSelected,
    onDialogClose,
    navigateToPricingPlan,
    onPreferencesModalServiceSelected,
    onServiceClick,
    onMoreInfoClick,
    onActionButtonClick,
    getAdditionalServicesData,
    loadServicesByPage,
  };
};
