import React from 'react';
import loadable from '@wix/yoshi-flow-editor/loadable';
import { useStyles } from '@wix/tpa-settings/react';
import {
  useBi,
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import type { ServicesPreferencesModalProps } from '@wix/bookings-services-preferences-modal/types';
import {
  StatesButtonStates as BUTTON_STATES,
  StatesButton,
  Text,
  TextTypography as TYPOGRAPHY,
  Dialog as DialogComponent,
  Button,
  ButtonPriority,
} from 'wix-ui-tpa/cssVars';
import { SpinnerWrapper } from '@wix/bookings-viewer-ui';
import { WidgetElements } from '../../../../utils/bi/consts';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import {
  DialogState,
  DialogType,
  DialogViewModel,
  GeneralDialogViewModel,
  ServicesPreferencesDialogViewModel,
} from '../../ViewModel/dialogViewModel/dialogViewModel';
import stylesParams from '../../stylesParams';
import {
  DataHooks,
  servicesPreferencesModalKeysMap as servicesPreferencesModalFormatterKeysMap,
} from './constants';
import { classes, st } from './Dialog.st.css';

export type DialogProps = {
  viewModel: DialogViewModel;
};

const ServicesPreferencesModal: React.FC<ServicesPreferencesModalProps> =
  loadable(() => import('@wix/bookings-services-preferences-modal'));

const Dialog: React.FC<DialogProps> = ({ viewModel }) => {
  const { type } = viewModel;
  const {
    onDialogClose,
    onPreferencesModalServiceSelected,
    onServicesPreferencesConfirm,
  } = useCalendarActions();
  const styles = useStyles();
  const { t } = useTranslation();
  const { isMobile, isRTL } = useEnvironment();
  const { experiments } = useExperiments();
  const biLogger = useBi();

  if (type === DialogType.ServicesPreferences) {
    return (
      <ServicesPreferencesModal
        {...(viewModel as ServicesPreferencesDialogViewModel).data}
        onConfirm={onServicesPreferencesConfirm}
        t={t}
        biLogger={biLogger}
        isMobile={isMobile}
        isRTL={isRTL}
        className={classes.servicesPreferencesModal}
        widgetStyles={{
          primaryColor: styles.get(stylesParams.bookButtonBGColor).value,
        }}
        onClose={onDialogClose}
        onServiceSelected={onPreferencesModalServiceSelected}
        formatterKeysMap={servicesPreferencesModalFormatterKeysMap}
        experiments={experiments}
        openedBy="calendar"
      />
    );
  }

  return <GeneralDialog viewModel={viewModel as GeneralDialogViewModel} />;
};

const GeneralDialog = ({
  viewModel,
}: {
  viewModel: GeneralDialogViewModel;
}) => {
  const { onDialogClose } = useCalendarActions();
  const { isMobile } = useEnvironment();
  const { isOpen, data, state } = viewModel;
  const { contentText, titleText, confirmButtonUrlLink, closeButtonText } =
    data;
  const isLoading = state === DialogState.LOADING;
  const { experiments } = useExperiments();
  const isCalendarFixLoadingButtonSizeWhenRescheduleEnabled =
    experiments.enabled(
      'specs.bookings.calendarFixLoadingButtonSizeWhenReschedule',
    );

  return (
    <DialogComponent
      data-hook={DataHooks.Root}
      isOpen={isOpen}
      onClose={() => onDialogClose()}
      className={st(classes.root, {
        isMobile,
        isLoading,
        isCalendarFixLoadingButtonSizeWhenRescheduleEnabled,
      })}
      notFullscreenOnMobile
    >
      {titleText && (
        <Text
          className={classes.title}
          data-hook={DataHooks.Title}
          typography={TYPOGRAPHY.largeTitle}
        >
          {titleText}
        </Text>
      )}
      <Text
        className={classes.content}
        data-hook={DataHooks.Content}
        typography={TYPOGRAPHY.listText}
      >
        {contentText}
      </Text>
      <div className={classes.buttonsContainer}>
        {confirmButtonUrlLink ? (
          <a
            href={confirmButtonUrlLink}
            target="_blank"
            data-hook={DataHooks.Link}
            rel="noreferrer"
          >
            <ConfirmButton viewModel={viewModel} />
          </a>
        ) : (
          <ConfirmButton viewModel={viewModel} />
        )}
        {closeButtonText ? <CloseButton text={closeButtonText} /> : null}
      </div>
    </DialogComponent>
  );
};

const ConfirmButton = ({
  viewModel,
}: {
  viewModel: GeneralDialogViewModel;
}) => {
  const { state, data } = viewModel;
  const { onDialogConfirm } = useCalendarActions();
  const buttonState: BUTTON_STATES =
    state === DialogState.IDLE ? BUTTON_STATES.IDLE : BUTTON_STATES.IN_PROGRESS;

  return (
    <StatesButton
      data-hook={DataHooks.ConfirmButton}
      className={classes.button}
      onClick={() => onDialogConfirm()}
      state={buttonState}
      idleContent={
        <Text className={classes.buttonText} typography={TYPOGRAPHY.listText}>
          {data.confirmButtonText}
        </Text>
      }
      inProgressContent={
        <SpinnerWrapper
          className={classes.spinner}
          diameter={24}
          isCentered={true}
        />
      }
    />
  );
};

const CloseButton = ({ text }: { text: string }) => {
  const { onDialogClose } = useCalendarActions();
  const { isMobile } = useEnvironment();

  return (
    <Button
      upgrade
      data-hook={DataHooks.CloseButton}
      onClick={() => onDialogClose(WidgetElements.DIALOG_CLOSE_BUTTON)}
      priority={ButtonPriority.basicSecondary}
      className={classes.closeButton}
      fullWidth={isMobile}
    >
      {text}
    </Button>
  );
};

export default Dialog;
