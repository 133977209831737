import { CreateActionParams } from '../actions';
import { TabTypes } from '../../types';
import { EventNames } from '../../core/fedops';

export type GetUpcoming = () => void;

export function createGetUpcomingAction({
  context,
  getControllerState,
}: CreateActionParams): GetUpcoming {
  return async () => {
    const { api, fedopsLogger, biLogger } = context;
    fedopsLogger?.interactionStarted(EventNames.UpcomingTabLoaded);

    const [state, setState] = getControllerState();

    const { bookings, selectedTimezone, businessInfo } =
      await api.getEnrichedBookings({
        type: TabTypes.UPCOMING,
        applicationState: state,
        withBookingPolicySettings: true,
      });

    api.logTabLoadedBi({
      tabName: TabTypes.UPCOMING,
      data: bookings,
      biLogger,
    });

    setState({
      upcomingData: bookings,
      selectedTimezone,
      businessInfo,
    });

    fedopsLogger?.interactionEnded(EventNames.UpcomingTabLoaded);
  };
}
