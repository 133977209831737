import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { isSlugAvailable } from '../../../server/account.api';
import type {
  ChangeEmailState,
  ChangePasswordState,
  Member,
  ThunkApiConfig,
  ToastState,
} from '../../../types';
import { Toast } from '../../../types';
import { ServerErrorCode } from '../../../types/server';
import { accountDataThunk } from '../../thunks/accountData';
import {
  recoverPassword,
  updateLoginEmail,
  updateLoginPassword,
} from '../../thunks/loginInfo';
import {
  makeProfilePrivate,
  makeProfilePublic,
  unblockMember,
  updateSlug,
} from '../member/thunk';

type State = {
  toast: ToastState;
  slug: {
    isLoading: boolean;
    isAvailable: boolean;
  };
  shouldOpenBlockedModal: boolean;
  changePasswordState: ChangePasswordState;
  changeEmailState: ChangeEmailState;
};

const querySlugAvailability = createAsyncThunk<
  boolean,
  Member['profile']['slug'],
  ThunkApiConfig
>('ui/querySlugAvailability', async (slug, { extra }) => {
  const { flowAPI } = extra;
  const { data } = await flowAPI.httpClient.request(isSlugAvailable(slug));
  return data;
});

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    toast: {
      isVisible: false,
      type: Toast.NONE,
      options: {},
    },
    slug: {
      isLoading: false,
      isAvailable: true,
    },
    dropdownOptions: {
      country: [],
      state: [],
    },
    shouldOpenBlockedModal: false,
    changePasswordState: { stage: 'initial' },
    changeEmailState: { stage: 'initial' },
  } as State,
  reducers: {
    setToast(state, action: PayloadAction<Toast>) {
      state.toast.type = action.payload;
    },
    hideToast(state) {
      state.toast.isVisible = false;
    },
    setSlugAvailability(state, action: PayloadAction<boolean>) {
      state.slug.isAvailable = action.payload;
    },
    setSlugLoading(state, action: PayloadAction<boolean>) {
      state.slug.isLoading = action.payload;
    },
    resetLoginInfoState(state) {
      state.changeEmailState = { stage: 'initial' };
      state.changePasswordState = { stage: 'initial' };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(querySlugAvailability.pending, (state) => {
        state.slug.isLoading = true;
      })
      .addCase(querySlugAvailability.fulfilled, (state, { payload }) => {
        state.slug.isAvailable = payload;
        state.slug.isLoading = false;
      })
      .addCase(querySlugAvailability.rejected, (state) => {
        state.slug.isAvailable = true;
        state.slug.isLoading = false;
      })
      .addCase(updateSlug.fulfilled, (state) => {
        state.toast.type = Toast.PROFILE_URL_UPDATE_SUCCESS;
        state.toast.isVisible = true;
      })
      .addCase(updateSlug.rejected, (state) => {
        state.toast.type = Toast.PROFILE_URL_UPDATE_ERROR;
        state.toast.isVisible = true;
      })
      .addCase(accountDataThunk.updateAccountData.fulfilled, (state) => {
        state.toast.type = Toast.FORM_UPDATE_SUCCESS;
        state.toast.isVisible = true;
      })
      .addCase(
        accountDataThunk.updateAccountData.rejected,
        (state, { payload }) => {
          switch (payload as ServerErrorCode) {
            case ServerErrorCode.INTERNAL:
              state.toast.type = Toast.FORM_UPDATE_ERROR_INTERNAL;
              break;
            case ServerErrorCode.UNAUTHENTICATED:
              state.toast.type = Toast.FORM_UPDATE_ERROR_UNAUTHENTICATED;
              break;
            case ServerErrorCode.UNAVAILABLE:
              state.toast.type = Toast.FORM_UPDATE_ERROR_UNAVAILABLE;
              break;
            case ServerErrorCode.PERMISSION_DENIED:
              state.toast.type = Toast.FORM_UPDATE_ERROR_PERMISSION_DENIED;
              break;
            case ServerErrorCode.INVALID_ARGUMENT:
              state.toast.type = Toast.FORM_UPDATE_ERROR_INVALID_ARGUMENT;
              break;
            case ServerErrorCode.INVALID_CUSTOM_FIELD_URL:
              state.toast.type = Toast.FORM_UPDATE_ERROR_INVALID_URL;
              break;
            default:
              state.toast.type = Toast.FORM_UPDATE_ERROR_UNKNOWN;
              break;
          }
          state.toast.isVisible = true;
        },
      )
      .addCase(makeProfilePublic.fulfilled, (state) => {
        state.toast.type = Toast.PROFILE_PRIVACY_CHANGE_SUCCESS;
        state.toast.isVisible = true;
      })
      .addCase(makeProfilePublic.rejected, (state) => {
        state.toast.type = Toast.PROFILE_PRIVACY_CHANGE_ERROR;
        state.toast.isVisible = true;
      })
      .addCase(makeProfilePrivate.fulfilled, (state) => {
        state.toast.type = Toast.PROFILE_PRIVACY_CHANGE_SUCCESS;
        state.toast.isVisible = true;
      })
      .addCase(makeProfilePrivate.rejected, (state) => {
        state.toast.type = Toast.PROFILE_PRIVACY_CHANGE_ERROR;
        state.toast.isVisible = true;
      })
      .addCase(unblockMember.fulfilled, (state, { payload }) => {
        state.toast.type = Toast.UNBLOCK_MEMBER_SUCCESS;
        state.toast.isVisible = true;

        if (state.toast.type === Toast.UNBLOCK_MEMBER_SUCCESS) {
          state.toast.options = { memberName: payload.name };
        }
      })
      .addCase(unblockMember.rejected, (state) => {
        state.toast.type = Toast.UNBLOCK_MEMBER_ERROR;
        state.toast.isVisible = true;
      })
      .addCase(updateLoginPassword.pending, (state) => {
        state.changePasswordState = { stage: 'pending' };
      })
      .addCase(updateLoginEmail.pending, (state) => {
        state.changeEmailState = { stage: 'pending' };
      })
      .addCase(updateLoginPassword.fulfilled, (state, { payload }) => {
        state.changePasswordState = payload;
        if (payload.stage === 'fail' && !payload.errors) {
          state.toast.type = Toast.LOGIN_INFO_TECHNICAL_ERROR;
          state.toast.isVisible = true;
        } else if (payload.stage === 'ok') {
          state.toast.type = Toast.LOGIN_INFO_PASSWORD_CHANGE_SUCCESS;
          state.toast.isVisible = true;
        }
      })
      .addCase(updateLoginEmail.fulfilled, (state, { payload }) => {
        state.changeEmailState = payload;
        if (payload.stage === 'fail' && !payload.errors) {
          state.toast.type = Toast.LOGIN_INFO_TECHNICAL_ERROR;
          state.toast.isVisible = true;
        } else if (payload.stage === 'ok') {
          state.toast.type = Toast.LOGIN_INFO_EMAIL_CHANGE_SUCCESS;
          state.toast.isVisible = true;
        }
      })
      .addCase(recoverPassword.fulfilled, (state) => {
        state.toast.type = Toast.LOGIN_INFO_RESET_PASSWORD_SUCCESS;
        state.toast.isVisible = true;
      })
      .addCase(recoverPassword.rejected, (state) => {
        state.toast.type = Toast.LOGIN_INFO_TECHNICAL_ERROR;
        state.toast.isVisible = true;
      });
  },
});

export const uiThunk = { querySlugAvailability };
