import type { TFunction } from '@wix/yoshi-flow-editor';
import { ToastSkin } from 'wix-ui-tpa/cssVars';

import { ToastType } from '../../../constants/toast';

export const getToastContent = (toastType: ToastType, t: TFunction) => {
  let toastSkin;
  let toastContent: string | null;

  switch (toastType) {
    case ToastType.ProfileDisplayInfoUpdated:
      toastContent = t('profile-widget.toast.display-info-updated');
      toastSkin = ToastSkin.success;
      break;

    case ToastType.ErrorSavingProfileDisplayInfo:
      toastContent = t('profile-widget.toast.display-info-update-error');
      toastSkin = ToastSkin.error;
      break;

    default:
      toastContent = null;
      toastSkin = ToastSkin.success;
  }

  return {
    toastContent,
    toastSkin,
  };
};
