import { createAsyncThunk } from '@reduxjs/toolkit';
import { MEMBERS_AREA } from '@wix/app-definition-ids';
import { actionButtonClicked } from '@wix/bi-logger-members-app-uou/v2';

import {
  MY_ACCOUNT_PAGE_WIDGET_ID,
  myAccountAppDefId,
  myAccountPageId,
} from '../../../constants';
import { updateAbout } from '../../../server/profile.api';
import type {
  MemberAbout,
  MembersAreaApi,
  ThunkApiConfig,
} from '../../../types';

export const navigateToMyAccount = createAsyncThunk<void, void, ThunkApiConfig>(
  'member/navigateToMyAccount',
  async (_, { getState, extra: { flowAPI, wixCodeApi } }) => {
    const state = getState();
    const membersAreaApi = await wixCodeApi.site
      .getPublicAPI(MEMBERS_AREA)
      .then((api) => api as MembersAreaApi | undefined);

    flowAPI.bi?.report(
      actionButtonClicked({
        action_type: 'edit_profile',
        formOrigin: 'profile_page',
        member_chosen: state.member.id ?? undefined,
      }),
    );

    await membersAreaApi?.navigateToSection({
      appDefinitionId: myAccountAppDefId,
      sectionId: myAccountPageId,
      widgetId: MY_ACCOUNT_PAGE_WIDGET_ID,
      memberId: state.member.id!,
      memberSlug: state.member.slug!,
    });
  },
);

export const publishMyAbout = createAsyncThunk<
  MemberAbout,
  MemberAbout,
  ThunkApiConfig
>(
  'member/publishMyAbout',
  async (newAbout, { extra: { flowAPI }, rejectWithValue }) => {
    try {
      const { data } = await flowAPI.httpClient.request(
        updateAbout({
          about: newAbout,
        }),
      );

      if ('error' in data) {
        return rejectWithValue(data.error);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
