import { BusinessInfo } from '../../../legacy/types';
import { getDefaultTimezone } from '../timezone/timezone';
import { EnrichedService } from '../../types/types';
import { getPaymentDescription } from '@wix/bookings-calendar-catalog-viewer-mapper';
import { Day } from '@wix/ambassador-calendar-v3-event/types';
import { WeekDay } from '@wix/bookings-uou-domain';

export const getOfferedDays = (
  service: EnrichedService,
  t: Function,
  isServiceListEventsClassDaysEnable: boolean,
) => {
  const legacyDayOrder =
    t('first-day-of-week') === t('week-day.sun')
      ? [
          WeekDay.SUNDAY,
          WeekDay.MONDAY,
          WeekDay.TUESDAY,
          WeekDay.WEDNESDAY,
          WeekDay.THURSDAY,
          WeekDay.FRIDAY,
          WeekDay.SATURDAY,
        ]
      : [
          WeekDay.MONDAY,
          WeekDay.TUESDAY,
          WeekDay.WEDNESDAY,
          WeekDay.THURSDAY,
          WeekDay.FRIDAY,
          WeekDay.SATURDAY,
          WeekDay.SUNDAY,
        ];
  const daysOrder =
    t('first-day-of-week') === t('week-day.sun')
      ? [
          Day.SUNDAY,
          Day.MONDAY,
          Day.TUESDAY,
          Day.WEDNESDAY,
          Day.THURSDAY,
          Day.FRIDAY,
          Day.SATURDAY,
        ]
      : [
          Day.MONDAY,
          Day.TUESDAY,
          Day.WEDNESDAY,
          Day.THURSDAY,
          Day.FRIDAY,
          Day.SATURDAY,
          Day.SUNDAY,
        ];

  const { offeredDays } = service;

  if (!offeredDays) {
    return t('offered-days.loading');
  }

  if (offeredDays.length === 7) {
    return t('working-hours.all-week');
  }
  const weekDayKeyPrefix: string =
    offeredDays.length <= 2 ? 'long-week-day.' : 'short-week-day.';
  return isServiceListEventsClassDaysEnable
    ? daysOrder
        .filter((day) => offeredDays.indexOf(day) > -1)
        .map((day) => t(weekDayKeyPrefix + day.toLowerCase().substring(0, 3)))
        .join(', ')
    : legacyDayOrder
        .filter((day) => offeredDays.indexOf(day) > -1)
        .map((day) => t(weekDayKeyPrefix + day))
        .join(', ');
};

export const getFormattedStartDate = ({
  service,
  language,
  t,
  businessInfo,
}: {
  service: EnrichedService;
  language: string;
  t: Function;
  businessInfo: BusinessInfo;
}) => {
  if (service.schedule?.firstSessionStart) {
    try {
      const startDate = new Date(service.schedule?.firstSessionStart);

      const isDateInCurrentYear = (date: Date) => {
        const now = new Date();
        return now.getFullYear() === date.getFullYear();
      };

      const isDateInThePast = (date: Date) => {
        const now = new Date();
        return date.valueOf() < now.valueOf();
      };

      const timeZone = getDefaultTimezone(businessInfo);
      const dateFormat: Intl.DateTimeFormatOptions = isDateInCurrentYear(
        startDate,
      )
        ? {
            month: 'short',
            day: 'numeric',
            timeZone,
          }
        : {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            timeZone,
          };

      const formattedStartDate = new Intl.DateTimeFormat(
        language,
        dateFormat,
      ).format(startDate);

      return isDateInThePast(startDate)
        ? `${t(
            'service.schedule.course.start.date.passed',
          )} ${formattedStartDate}`
        : `${t(
            'service.schedule.course.start.date.future',
          )} ${formattedStartDate}`;
    } catch (e) {
      return '';
    }
  }
  return '';
};

export const getPrice = ({
  service,
  locale,
  t,
}: {
  service: EnrichedService;
  language: string;
  locale?: string;
  t: Function;
}): {
  displayedPrice: string;
  srOnlyPrice: string;
} => {
  const getPaymentDescriptionArgs: Parameters<typeof getPaymentDescription>[0] =
    {
      payment: service.payment!,
      regionalSettingsLocale: locale!,
      paymentFormatters: {
        variedPrice: ({ minPrice }) =>
          t('service.payment.from-price', { price: minPrice }),
      },
    };

  return {
    displayedPrice: getPaymentDescription(getPaymentDescriptionArgs),
    srOnlyPrice: getPaymentDescription({
      ...getPaymentDescriptionArgs,
      currencyDisplay: 'name',
    }),
  };
};
