import React from 'react';
import { Card } from 'wix-ui-tpa/cssVars';
import { useAddVisibilityClass } from '../../../../hooks/useAddVisibilityClass';
import type { ServiceLayoutProps } from '../ServiceCard';
import { classes as serviceCardClasses } from '../ServiceCard.st.css';
import { DataHooks as CardDataHooks } from '../Card/consts';
import { DataHooks } from '../consts';
import { classes, st } from './LayoutCard.st.css';
import { useExperiments } from '@wix/yoshi-flow-editor';

export function LayoutCard({ image, info, index }: ServiceLayoutProps) {
  const { addVisibilityClassWithoutCondition } = useAddVisibilityClass();
  const { experiments } = useExperiments();
  const isServiceListImageCornenrRadiusEnabled = experiments.enabled(
    'specs.bookings.serviceListImageCornerRadius',
  );
  return (
    <Card
      className={st(classes.root, {
        even: (index ?? 0) % 2 === 0,
        isServiceListImageCornenrRadiusEnabled,
      })}
      data-hook={DataHooks.CARD}
    >
      {image ? (
        <Card.Container
          data-hook={CardDataHooks.IMAGE}
          className={addVisibilityClassWithoutCondition(
            classes.media,
            serviceCardClasses.serviceImageVisibility,
          )}
        >
          {image}
        </Card.Container>
      ) : null}
      <Card.Container data-hook={CardDataHooks.INFO} className={classes.info}>
        {info}
      </Card.Container>
    </Card>
  );
}
